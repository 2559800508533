import React from 'react';

import BlogSidebar from './BlogSidebar';
import List from '../../components/Febos/List';

const BlogDetailsContent = ({ post = [], posts = [], prevPost = null, nextPost = null }) => {
  return (
    <section className="blog-details-area ptb-100">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-12">
            <div className="blog-details-desc">
              <div className="article-image">
                <img
                  src={post.image}
                  alt="image"
                />
              </div>

              <div className="article-content">
                <div className="entry-meta">
                  <ul>
                    <li>
                      <i className="bx bx-folder-open"></i>
                      <span>Categoria</span>
                      <a
                        href={'/blog/?page=1&category=' + post.category}
                      >{post.category}</a>
                    </li>
                    <li>
                      <i className="bx bx-group"></i>
                      <span>Vistas</span>
                      {post.views}
                    </li>
                    <li>
                      <i className="bx bx-calendar"></i>
                      <span>Fecha</span>
                      {post.date}
                    </li>
                  </ul>
                </div>

                <h3>{post.title}</h3>
                {
                  Object.keys(post.content).map((i) => {
                    let kind = Object.keys(post.content[i])[0];
                    let content = post.content[i][kind];
                    if (kind === 'p') {
                      return <p
                        dangerouslySetInnerHTML={{ __html: content }}
                        key={'body-' + i}
                      ></p>;
                    }
                    if (kind === 'h') {
                      return <h3
                        dangerouslySetInnerHTML={{ __html: content }}
                        key={'body-' + i}
                      ></h3>;
                    }
                    if (kind === 'q') {
                      return <blockquote>
                        <p
                          dangerouslySetInnerHTML={{ __html: content }}
                          key={'body-' + i}
                        ></p>
                      </blockquote>;
                    }
                    if (kind === 'ul') {
                      return <ul
                        className="features-list"
                        key={'body-' + i}
                      >
                        <List items={content}></List>
                      </ul>;
                    }
                    if (kind === 'img') {
                      let clases = 'wp-block-gallery columns-3';
                      return <ul
                        className={clases}
                        key={'body-' + i}
                      >
                        <List items={content}></List>
                      </ul>;
                    }
                  })
                }

              </div>

              <div className="article-footer">
                <div className="article-tags">
                  <span>
                    <i className="bx bx-purchase-tag"></i>
                  </span>
                  {
                    post.tags.map((tag, i) => {
                      return <a
                        href={'/blog/?page=1&tag=' + tag}
                        key={'t-' + i}
                      >{tag}</a>;
                    })
                  }
                </div>
              </div>
              <div className="tracer-post-navigation">
                {
                  prevPost &&
                  <div className="prev-link-wrapper">
                    <div className="info-prev-link-wrapper">
                      <a
                        href={'/articulo/?id=' + prevPost.id + '&title=' +
                          prevPost.title.replaceAll(' ', '-')}
                      >
                      <span className="image-prev">
                        <img
                          src={prevPost.image}
                          alt="bb"
                        />
                        <span className="post-nav-title">Anterior</span>
                      </span>

                        <span className="prev-link-info-wrapper">
                        <span className="prev-title">
                          {prevPost.title}
                        </span>
                        <span className="meta-wrapper">
                          <span className="date-post">{prevPost.date}</span>
                        </span>
                      </span>
                      </a>
                    </div>
                  </div>
                }
                {
                  nextPost &&
                  <div className="next-link-wrapper">
                    <div className="info-next-link-wrapper">
                      <a
                        href={'/articulo/?id=' + nextPost.id + '&title=' +
                          nextPost.title.replaceAll(' ', '-')}
                      >
                      <span className="next-link-info-wrapper">
                        <span className="next-title">
                          {nextPost.title}
                        </span>
                        <span className="meta-wrapper">
                          <span className="date-post">{nextPost.date}</span>
                        </span>
                      </span>

                        <span className="image-next">
                        <img
                          src={nextPost.image}
                          alt="bb"
                        />
                        <span className="post-nav-title">Siguiente</span>
                      </span>
                      </a>
                    </div>
                  </div>
                }
              </div>

            </div>
          </div>

          <div className="col-lg-4 col-md-12">
            <BlogSidebar
              post={post}
              posts={posts}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogDetailsContent;
