import React from 'react';
import BlogDetailsContent from "../BlogContent/BlogDetailsContent";
import img4 from "../../assets/images/blog/blog-img4.jpg";

const List = ({items}) => {
  if(items[0].toLowerCase().startsWith("http")){
    return (
      items.map((el, i) => {
        return <li className="blocks-gallery-item" key={'item-' + i}>
          <figure>
            <img src={el} />
          </figure>
        </li>
      })
    )
  }else {
    return (
      items.map((el, i) => {
        return <li key={'item-' + i}>
          <i className="bx bx-badge-check"></i>
          <span dangerouslySetInnerHTML={{__html: el}}></span>
        </li>
      })
    )
  }
}
export default List
