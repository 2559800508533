import React from 'react';
import Layout from '../components/App/Layout';
import Navbar from '../components/App/Navbar';
import PageBanner from '../components/Common/PageBanner';
import Footer from '../components/App/Footer';
import BlogDetailsContent from '../components/BlogContent/BlogDetailsContent';
import useEffectBlogDetails from '../hooks/useEffectBlogDetails';

const Articulo = () => {
  const {
    post,
    prevPost,
    nextPost,
    posts
  } = useEffectBlogDetails( );
  return (

    <Layout>
      <Navbar />
      <PageBanner
        pageTitle={post?.title || ''}
        homePageText="Inicio"
        homePageUrl="/"
        activePageText="Blog"
      />
      {
        post && <BlogDetailsContent
          post={post}
          prevPost={prevPost}
          nextPost={nextPost}
          posts={posts}
        />
      }
      <Footer />
    </Layout>
  );
};

export default Articulo;
