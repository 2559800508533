import { useEffect, useState } from 'react';

export function useEffectPosts({ actualPosId }) {
  const [loading, setLoading] = useState(false);
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    setLoading(true)
    // Cuando se renderiza el componente
    fetch('https://archivos.febos.io/publico/blog/blog.json')
    .then((response) => response.json())
    .then((responseJson) => {
      setPosts(responseJson.posts);
    })
    .catch((error) => {
      console.error(error);
    });
    setLoading(false)
    return () => {
      // Funcion cuando se desmonta
    };
  }, [setPosts]);

  return {
    posts,
    loading
  };
}

export default useEffectPosts;
