import {useEffect, useMemo, useState} from 'react';

import useEffectPosts from './useEffectPosts';

export function useEffectBlogDetails() {
  const location = useMemo(() => {
    {
      if (typeof window !== 'undefined') {
        // Code that references document goes here.
        return window.location
      }
      return {search: ''};
    }
  }, []);
  const search = useMemo(() => (location.search), [location]);
  const [post, setPost] = useState(null);
  const [prevPost, setPrevPost] = useState(null);
  const [nextPost, setNextPost] = useState(null);
  const postId = useMemo(() => {
    return parseInt(search.split('&')[0].split('=')[1]);
  }, [search]);

  const {posts} = useEffectPosts({actualPosId: postId});

  const actualPosIndex = useMemo(() => (posts.findIndex(p => p.id == postId)), [posts, postId]);

  useEffect(() => {
    // Cuando se renderiza el componente
    if (posts[actualPosIndex]) {
      setPost(posts[actualPosIndex]);
    } else {
      setPost(null);
    }
    if (posts[actualPosIndex - 1]) {
      setPrevPost(posts[actualPosIndex - 1]);
    } else {
      setPrevPost(null);
    }
    if (posts[actualPosIndex + 1]) {
      setNextPost(posts[actualPosIndex + 1]);
    } else {
      setNextPost(null);
    }
  }, [actualPosIndex, posts]);

  return {
    post,
    prevPost,
    nextPost,
    actualPosIndex,
    posts
  };
}

export default useEffectBlogDetails;
