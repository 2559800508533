import React from 'react';

const BlogSidebar = (
  {
    post = {
      id: '',
      image: '',
      title: '',
      tags: [],
      content: [],
      refs: []
    },
    posts = []
  }
) => {
  const categories = {};
  const tags = {};
  for (let i = 0; i < posts.length; i++) {
    const item_post = posts[i];
    const currentCategory = item_post.category;
    const categoryCount = typeof categories[currentCategory] === 'undefined'
      ? 0
      : categories[currentCategory];
    categories[currentCategory] = categoryCount + 1;

    for (let j = 0; j < item_post.tags.length; j++) {
      const tag = item_post.tags[j];
      const tagCount = typeof tags[tag] === 'undefined' ? 0 : tags[tag];
      tags[tag] = tagCount + 1;
    }
  }
  const refPosts = [];
  for (let i = 0; i < post.refs.length; i++) {
    const found = posts.find(p => p.id == post.refs[i]);
    if(found) {
      refPosts.push(found);
    }
  }

  return (
    <div className="widget-area">

      <div className="widget widget_tracer_posts_thumb">
        <h3 className="widget-title">Te podría interesar</h3>
        {
          refPosts.map((p, i) => {
            return <article
              className="item"
              key={'ref-' + i}
            >
              <a
                href={'/articulo/?id=' + p.id + '&title=' + p.title.replaceAll(' ', '-')}
                className="thumb"
              >
                <img src={p.image}></img>
              </a>
              <div className="info">
                <span>{p.date}</span>
                <h4 className="title usmall">
                  <a href={'/articulo/?id=' + p.id + '&title=' + p.title.replaceAll(' ', '-')}>
                    {p.title}
                  </a>
                </h4>
              </div>

              <div className="clear"></div>
            </article>;
          })
        }
      </div>

      <div className="widget widget_categories">
        <h3 className="widget-title">Categorias</h3>

        <ul>
          {
            Object.keys(categories).map((category, i) => {
              return <li key={'c-' + i}>
                <a href={'/blog/?page=1&category=' + category}>
                  {category} <span className="post-count">({categories[category]})</span>
                </a>
              </li>;
            })
          }
        </ul>
      </div>

      <div className="widget widget_tag_cloud">
        <h3 className="widget-title">Tags populares</h3>

        <div className="tagcloud">
          {
            Object.keys(tags).map((tag, i) => {
              return <a
                href={'/blog/?page=1&tag=' + tag}
                key={'t-' + i}
              >
                {tag} <span className="tag-link-count">({tags[tag]})</span>
              </a>;
            })
          }
        </div>
      </div>
    </div>
  );
};

export default BlogSidebar;
